function apiClient(baseApiUrl, csrfToken) {
    var self = this;
    self.apiUrl = baseApiUrl;
    self.csrfToken = csrfToken;

    self.defaultErrorHandler = function(jqXHR, textStatus, errorThrown) {
        if (typeof jqXHR.responseJSON.error.message !== "undefined") {
            alert(jqXHR.responseJSON.error.message + ' (' + errorThrown + ')');
        }
    };

    self.setErrorHandler = function(request, error) {
        if (typeof error !== "undefined") {
            request.error(error);
        } else {
            request.error(self.defaultErrorHandler);
        }
    };

    self.get = function(method, data, callback, error) {
        var request = $.ajax({
            url: self.apiUrl + method,
            method: 'GET',
            data: data,
            dataType: 'json'
        }).done(callback);
        self.setErrorHandler(request, error);
    };

    self.post = function(method, data, callback, error) {
        data._token = self.csrfToken;
        var request = $.ajax({
            url: self.apiUrl + method,
            method: 'POST',
            data: data,
            dataType: 'json'
        }).done(callback);
        self.setErrorHandler(request, error);
    };

    self.put = function(method, data, callback, error) {
        data._token = self.csrfToken;
        var request = $.ajax({
            url: self.apiUrl + method,
            method: 'PUT',
            data: data,
            dataType: 'json'
        }).done(callback);
        self.setErrorHandler(request, error);
    };

    self.delete = function(method, data, callback, error) {
        data._token = self.csrfToken;
        var request = $.ajax({
            url: self.apiUrl + method,
            method: 'DELETE',
            data: data,
            dataType: 'json'
        }).done(callback);
        self.setErrorHandler(request, error);
    };

    self.fileUpload = function(method, data, callback, error) {
        data._token = self.csrfToken;
        var request = $.ajax({
            url: self.apiUrl + method,
            method: 'POST',
            data: data,
            dataType: 'json',
            contentType: false,
            processData: false
        }).done(callback);
        self.setErrorHandler(request, error);
    };
}
