/**
 * Initialize Form.
 * @param container
 * @param url
 * @param callbacks
 */
function initializeForm(container, url, callbacks) {
    // First cleanup container before firing AJAX.
    container.html('');
    $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        success: function (res) {
            container.html(res.view);
            if (typeof callbacks !== "undefined" && typeof callbacks.onLoad === "function") {
                callbacks.onLoad();
            }
            var $form = container.find('form');

            var onStepCloseHandler;
            if (typeof callbacks !== "undefined" && typeof callbacks.onStepClose === "function") {
                onStepCloseHandler = callbacks.onStepClose;
            } else {
                onStepCloseHandler = function() {
                    // If container contains redirect attribute - redirect.
                    if (res.success === true && container.data('redirect') != undefined) {
                        location.href = container.data('redirect');
                    }
                }
            }

            var defaultSubmitHandler = function(ev) {
                ev.preventDefault();
                var data = $form.serializeArray();
                var createElement = function() {
                    $.ajax({
                        url: $form.attr('action'),
                        type: $form.find('[name="_method"]').length > 1 ? $form.find('[name="_method"]').val() : $form.attr('method'),
                        dataType: 'json',
                        data: data,
                        success: function (res) {
                            if (res.success === false && res.errors != undefined) {
                                $form.find('.alert-danger').remove();
                                $form.prepend(res.errors);
                            } else {
                                // On save will be loaded only when validation is OK.
                                if (typeof callbacks !== "undefined" && typeof callbacks.onSave === "function") {
                                    callbacks.onSave(res, callbacks);
                                }
                            }
                        }
                    });
                };
                if(typeof callbacks.confirmSubmit !== "undefined" && callbacks.confirmSubmit === true) {
                    var confirmSubmitMessage = "Czy na pewno chcesz stworzyc element i dodac go do bazy?";
                    if(typeof callbacks.confirmSubmitMessage !== "undefined") {
                        confirmSubmitMessage = callbacks.confirmSubmitMessage;
                    }
                    swal({
                        title: "Uwaga",
                        text: confirmSubmitMessage,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Tak",
                        cancelButtonText: "Nie",
                        closeOnConfirm: true
                    }, function (confirmed) {
                        confirmed === true ? createElement() : false;
                    });
                } else {
                    createElement();
                }
            };

            var onSubmitHandler;
            if (typeof callbacks !== "undefined" && typeof callbacks.onSubmit === "function") {
                onSubmitHandler = callbacks.onSubmit(defaultSubmitHandler, callbacks);
            } else {
                onSubmitHandler = defaultSubmitHandler;
            }

            var onDestroyHandler;
            if (typeof callbacks !== "undefined" && typeof callbacks.onDestroy === "function") {
                onDestroyHandler = callbacks.onDestroy;
            } else {
                onDestroyHandler = function(ev) {
                    ev.preventDefault();
                    var removeElement = function() {
                        $.ajax({
                            url: $('.destroy-form').data('url'),
                            type: $('.destroy-form').data('method'),
                            dataType: 'json',
                            success: function (res) {
                                if(typeof callbacks.afterDestroyCallback !== "undefined") {
                                    callbacks.afterDestroyCallback(res);
                                } else {
                                    location.href = $('.destroy-form').data('redirect');
                                }
                            }
                        });
                    };
                    swal({
                        title: "Uwaga",
                        text: "Czy na pewno chcesz usunąć wybrany element z bazy?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Tak",
                        cancelButtonText: "Nie",
                        closeOnConfirm: true
                    }, function(confirmed) {
                        confirmed === true ? removeElement() : false;
                    });

                }
            }

            $form.find('.submit-form').on('click tap', onSubmitHandler);
            $form.find('.destroy-form').on('click tap', onDestroyHandler);

            if(typeof callbacks !== "undefined" && typeof callbacks.onLoaded === "function") {
                callbacks.onLoaded();
            }

            if(typeof $().selectize === "function") {
                for(var i = 0; i < container.find('.form-control.selectize').length; i++) {
                    if(typeof container.find('.form-control.selectize')[i].selectize === "object") {
                        container.find('.form-control.selectize')[i].selectize.destroy();
                    }
                }
                container.find('.selectize').selectize();
            }
        },
        error: function(errors) {
            container.html('<h3>' + container.data('error-message') + '</h3>');
        }
    });
}