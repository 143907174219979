function bindDelFileBtn() {
    $('.btn-del-files').on('click tap', function(ev) {
        ev.preventDefault();
        var $thisBtn = $(this);
        var removeRow = function() {
            $thisBtn.parents('.file-row').remove();
        };
        swal({
            title: "Uwaga",
            text: "Czy na pewno chcesz usunąć przypisany plik?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Tak",
            cancelButtonText: "Nie",
            closeOnConfirm: true
        }, function(confirmed) {
            confirmed === true ? removeRow() : false;
        });
    });
}

function fileTreeBrowser($tree, shallowTreeUrl, deepTreeUrl) {
    var $data = {};

    api.get(shallowTreeUrl, {}, function(res) {
        $data = res.tree;

        var searchNodeForIdMatch = function (node, id, nodesToAttach) {
            if(node.isDir === true && node.id == id) {
                node.nodes = nodesToAttach;
                return true;
            } else {
                if(typeof node.nodes !== "undefined") {
                    for(var o = 0; o < node.nodes.length; o++) {
                        if (searchNodeForIdMatch(node.nodes[o], id, nodesToAttach)) {
                            return true;
                        }
                    }
                }
            }
            return false;
        };

        var searchForIdMatch = function($dataToSearch, id, nodesToAttach) {
            for(var i = 0; i < $dataToSearch.length; i++) {
                if (searchNodeForIdMatch($dataToSearch[i], id, nodesToAttach)) {
                    break;
                }
            }
            $data = $dataToSearch;
        };

        var selectNode = function(path, id) {
            var $selectedFile = $('#selected-file');
            if(typeof path === "undefined") {
                $selectedFile.removeAttr('data-path');
                $selectedFile.html('');
            } else {
                $selectedFile.attr('data-path', path);
                $selectedFile.html('Selected: ' + path);
            }
            if(typeof id === "undefined") {
                $selectedFile.removeAttr('data-id');
            } else {
                $selectedFile.attr('data-id', id);
            }
        };

        var initTree = function(afterInitializationCallback, a) {
            var tree = $tree.treeview({
                data: $data,
                levels: 0,
                onNodeSelected: function(event, data) {
                    // Pick up from $data only if any of array element's contain value data.id of ID key.
                    if(data.isDir === true) {
                        // If directory is clicked - load ajax its contents.
                        selectNode();
                        api.get(deepTreeUrl, {id: data.id}, function(res) {

                            if(res.nodes.length != 0) {
                                // Determine which node was clicked.
                                searchForIdMatch($($data), data.id, res.nodes);

                                // Open all nodes until the one that was clicked.
                                initTree(function() {

                                    if(typeof data.parentId !== "undefined") {
                                        $tree.treeview('expandNode', data.parentId, { levels: 2, silent: true });

                                        var loopForParentIds = function(parentNode) {
                                            if(typeof parentNode.parentId !== "undefined") {
                                                $tree.treeview('expandNode', parentNode.parentId, {
                                                    levels: 2,
                                                    silent: true
                                                });
                                                loopForParentIds($tree.treeview('getNode', parentNode.parentId));
                                            } else {
                                                return false;
                                            }
                                        };

                                        loopForParentIds($tree.treeview('getNode', data.parentId));
                                    }
                                    $tree.treeview('expandNode', data.nodeId, { levels: 2, silent: true });
                                });
                            }
                        });
                    } else {
                        // Use selected file and display it.
                        selectNode(data.path, data.id);
                    }
                }
            });
            if(typeof afterInitializationCallback === "function") {
                afterInitializationCallback();
            }
            return tree;
        }

        var $searchableTree = initTree();

        var searchTree = function(e) {
            var pattern = $('#input-search').val();

            // Start searching when length is above 2.
            if(pattern.length > 2) {
                var options = {
                    ignoreCase: true,
                    exactMatch: false,
                };
                var results = $searchableTree.treeview('search', [ pattern, options ]);

                var output = '<p>' + results.length + ' matches found</p>';
                $.each(results, function (index, result) {
                    output += '<p>- ' + result.text + '</p>';
                });
            }
        }

        $('#btn-add-item').unbind('click tap');

        $('#btn-search').on('click tap', searchTree);
        $('#input-search').on('keyup', searchTree);

        $('#btn-clear-search').on('click tap', function (e) {
            $searchableTree.treeview('clearSearch');
            $('#input-search').val('');
        });

        $('#btn-add-item').on('click tap', function (e) {
            var $attachedFiles = $('#attached-files');
            var $selectedFile = $('#selected-file');
            // If anything's selected.
            if(typeof $selectedFile.attr('data-path') !== "undefined") {
                var $newFile = $attachedFiles.find('.file-example').clone();
                $newFile.removeClass('file-example hidden').appendTo('#attached-files .files');
                var path = $selectedFile.attr('data-path');
                $attachedFiles.find('input[name="attachment_path[]"]').last().val(path);
                var filename = path.split('\\').pop();
                $attachedFiles.find('input[name="attachment_name[]"]').last().val(filename);
                bindDelFileBtn();
            }
        });

    });
}