/**
 * Init API
 * @type {apiClient}
 */
var api = new apiClient(apiUrl, $('meta[name="csrf-token"]').attr('content'));
var dontRedirect = false;
var saveAndRedirect = function(res) {
    if (res.success) {
        if (!dontRedirect) {
            window.location = res.redirectUrl;
        }
    } else {
        alert(res.error.message);
    }
};
var saveAndReload = function(res) {
    if (res.success) {
        if (!dontRedirect) {
            window.location.reload();
        }
    } else {
        alert(res.error.message);
    }
};
var alertAndReload = function(text) {
    return function() {
        alert(text);
        window.location.reload();
    }
};
var getDataTableDefaultOptions = function () {
    return {
        iDisplayLength: 100,
        responsive: true,
        language: {
            "processing":     "Przetwarzanie...",
            "search":         "Szukaj:",
            "lengthMenu":     "Pokaż _MENU_ pozycji",
            "info":           "Pozycje od _START_ do _END_ z _TOTAL_ łącznie",
            "infoEmpty":      "Pozycji 0 z 0 dostępnych",
            "infoFiltered":   "(filtrowanie spośród _MAX_ dostępnych pozycji)",
            "infoPostFix":    "",
            "loadingRecords": "Wczytywanie...",
            "zeroRecords":    "Nie znaleziono pasujących pozycji",
            "emptyTable":     "Brak danych",
            "paginate": {
                "first":      "Pierwsza",
                "previous":   "Poprzednia",
                "next":       "Następna",
                "last":       "Ostatnia"
            },
            "aria": {
                "sortAscending": ": aktywuj, by posortować kolumnę rosnąco",
                "sortDescending": ": aktywuj, by posortować kolumnę malejąco"
            }
        }
    };
};

/**
 * Init Global functions
 * @type {undefined}
 */
var bindAddContact = function() {
    $('.add-contact').on('click tap', function(ev) {
        ev.preventDefault();
        var sampleContact = $('.contact-1').clone();
        sampleContact.find('input').val('');
        sampleContact.find('.num').html($('.contact').length + 1);
        $('.contacts').append(sampleContact);
    });
}

/**
 * Init Calendar and DataTables
 * @type {undefined}
 */
var globalSearchType = undefined;
var globalSearchTypeId = undefined;

$(function() {

    if(typeof role !== "undefined") {
        var isAdmin = role.indexOf('Administrator') === -1 ? false : true;
        var isProducent = role.indexOf('Producentka') === -1 ? false : true;
        var isRealizator = role.indexOf('Realizator') === -1 ? false : true;
    } else {
        var isAdmin = false;
        var isProducent = false;
        var isRealizator = false;
    }
    var $calendar = $('#calendar');
    var $clientsProjectSelectObject = $('#clientsProjectsSelect');
    var $clientsProjectSwitchObject = $('#clientsProjectsSwitch');
    var $filteringSelect = $clientsProjectSelectObject.selectize(selectizeSearch("projects"));
    var $elementPopup = $('.calendar-item-popup');

    var events = [];
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "progressBar": true,
        "positionClass": "toast-top-right",
        "onclick": null,
        "showDuration": "400",
        "hideDuration": "1000",
        "timeOut": "2000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

    var form = []
    form.commonHandlers = {
        closeSubForm: function(container, newContainer) {
            newContainer.html('');
            container.removeClass('hidden');
        },
    };
    form.options = {
        addAttachmentsHandler: function() {
            $('.next-attachment').on('click tap', function(ev) {
                ev.preventDefault();
                if(!$(this).hasClass('disabled')) {
                    $('.attachments-group').last().after($('.attachments-group')[0].outerHTML);
                    $('.attachments-group').last().find('input').val('');
                }
            });
        },
        addDatesHandler: function () {
            $('.next-date').on('click tap', function(ev) {
                ev.preventDefault();
                if(!$(this).hasClass('disabled')) {
                    $('.dates-group').last().after($('.dates-group')[0].outerHTML);
                }
            });
        },
        addLectorCreateHandler: function(container, newContainer) {
            $('.lector-create').on('click tap', function(ev) {
                ev.preventDefault();
                initializeForm(newContainer, $(this).data('url'), {
                    onLoad: function() {
                        container.addClass('hidden');
                        newContainer.removeClass('hidden');
                        newContainer.find('.submit-form').before('<button class="back-button btn btn-sm btn-warning">Wróć</button>');
                        newContainer.find('.back-button').on('click tap', function(ev) {
                            ev.preventDefault();
                            form.commonHandlers.closeSubForm(container, newContainer);
                        });
                    },
                    onSave: function(defaultHandler, callbacks) {
                        var apiResult = defaultHandler;
                        if(apiResult.success == true) {
                            form.commonHandlers.closeSubForm(container, newContainer);
                        }

                        var $lectorsGroup = container.find('.lectors-group .selectize');
                        $lectorsGroup[0].selectize.addOption({value: apiResult.lectorId, text: apiResult.lectorName});
                        $lectorsGroup[0].selectize.addItem(apiResult.lectorId);
                    },
                })
            });
        },
        addClientCreateHandler: function(container, newContainer) {
            $('.client-create').on('click tap', function(ev) {
                ev.preventDefault();
                initializeForm(newContainer, $(this).data('url'), {
                    onLoad: function() {
                        container.addClass('hidden');
                        newContainer.removeClass('hidden');
                        newContainer.find('.submit-form').before('<button class="back-button btn btn-sm btn-warning">Wróć</button>');
                        newContainer.find('.back-button').on('click tap', function(ev) {
                            ev.preventDefault();
                            form.commonHandlers.closeSubForm(container, newContainer);
                        });
                    },
                    onSave: function(defaultHandler, callbacks) {
                        var apiResult = defaultHandler;
                        if(apiResult.success == true) {
                            callbacks.onClientsSelected(apiResult);
                            form.commonHandlers.closeSubForm(container, newContainer);
                        }
                    },
                    onClientsSelected: function(apiResult) {
                        // Load selectize with fetchLastClient parameter set to true.
                        initializeSelectizeProjectsAndClients(true);
                    }
                })
            });
        },
        addProjectCreateHandler: function(container, newContainer) {
            var $this = this;
            $('.project-create').on('click tap', function(ev) {
                ev.preventDefault();
                initializeForm(newContainer, $(this).data('url'), {
                    onLoad: function() {
                        container.addClass('hidden');
                        newContainer.removeClass('hidden');
                        newContainer.find('.submit-form').before('<button class="back-button btn btn-sm btn-warning">Wróć</button>');
                        newContainer.find('.back-button').on('click tap', function(ev) {
                            ev.preventDefault();
                            newContainer.html('');
                            container.removeClass('hidden');
                        });
                        initializeSelectizeProjectsAndClients();
                        $this.addLectorCreateHandler(newContainer, $('#eventSubSubFormContainer'));
                        var $oldClientsSelect = $('#clientsSelect, #clientsSelect-2').first();
                        var $newClientsSelect = $('#clientsSelect, #clientsSelect-2').last();
                        $newClientsSelect[0].selectize.setValue($oldClientsSelect[0].selectize.getValue());
                        jscolor.installByClassName("jscolor");
                    },
                    onSave: function(defaultHandler, callbacks) {
                        var apiResult = defaultHandler;
                        if(apiResult.success == true) {
                            callbacks.onProjectsSelected(apiResult);
                            newContainer.html('');
                            container.removeClass('hidden');
                        }
                    },
                    onProjectsSelected: function(apiResult) {
                        initializeSelectizeProjectsAndClients(false, false, true, apiResult.clientId);
                    }
                })
            });
        },
        addProjectEditHandler: function(container, newContainer) {
            var $this = this;
            $('.project-edit').unbind('click tap');
            $('.project-edit').on('click tap', function(ev) {
                ev.preventDefault();
                initializeForm(newContainer, $(this).data('url'), {
                    onLoad: function() {
                        container.addClass('hidden');
                        newContainer.removeClass('hidden');
                        newContainer.find('.submit-form').before('<button class="back-button btn btn-sm btn-warning">Wróć</button>');
                        newContainer.find('.back-button').on('click tap', function(ev) {
                            ev.preventDefault();
                            newContainer.html('');
                            container.removeClass('hidden');
                        });
                        $this.addLectorCreateHandler(newContainer, $('#eventSubSubFormContainer'));
                        initializeSelectizeProjectsAndClients();
                        jscolor.installByClassName("jscolor");
                    },
                    onSave: function(defaultHandler, callbacks) {
                        var apiResult = defaultHandler;
                        if(apiResult.success == true) {
                            callbacks.onProjectsUpdate(apiResult);
                            newContainer.html('');
                            container.removeClass('hidden');
                        }
                    },
                    afterDestroyCallback: function(apiResult) {
                        location.reload();
                    },
                    onProjectsUpdate: function(apiResult) {
                        $('.project-edit').html(apiResult.projectName);
                        $('.project-public-notes').html(apiResult.publicNotes);
                        $('.project-studio-notes').html(apiResult.studioNotes);

                        var $lectors = $('.lectors');
                        $lectors.html('');
                        $.each(apiResult.lectors, function(index, el) {
                            $lectors.append('<li>'+el.name+'</li>');
                        });

                        var $producents = $('.producents');
                        $producents.html('');
                        $.each(apiResult.producents, function(index, el) {
                            $producents.append('<li>'+el.name+'</li>');
                        });
                    }
                });
            });
        },
        addClientEditHandler: function(container, newContainer) {
            $('.client-edit').unbind('click tap');
            $('.client-edit').on('click tap', function(ev) {
                ev.preventDefault();
                initializeForm(newContainer, $(this).data('url'), {
                    onLoad: function() {
                        container.addClass('hidden');
                        newContainer.removeClass('hidden');
                        newContainer.find('.submit-form').before('<button class="back-button btn btn-sm btn-warning">Wróć</button>');
                        newContainer.find('.back-button').on('click tap', function(ev) {
                            ev.preventDefault();
                            newContainer.html('');
                            container.removeClass('hidden');
                        });
                        initializeSelectizeProjectsAndClients();
                    },
                    onSave: function(defaultHandler, callbacks) {
                        var apiResult = defaultHandler;
                        if(apiResult.success == true) {
                            callbacks.onClientsUpdate(apiResult);
                            newContainer.html('');
                            container.removeClass('hidden');
                        }
                    },
                    afterDestroyCallback: function(apiResult) {
                        location.reload();
                    },
                    onClientsUpdate: function(apiResult) {
                        $('.client-edit').html(apiResult.clientName);
                        $('.client-studio-notes').html(apiResult.studioNotes);
                    }
                });
            });
        },
        addResponderHandler: function(container, newContainer) {
            $('.mailer-create').on('click tap', function(ev) {
                ev.preventDefault();
                initializeForm(newContainer, $(this).data('url'), {
                    onLoad: function() {
                        container.addClass('hidden');
                        newContainer.removeClass('hidden');
                        newContainer.find('.submit-form').before('<button class="back-button btn btn-sm btn-warning">Wróć</button>');
                        newContainer.find('.back-button').on('click tap', function(ev) {
                            ev.preventDefault();
                            newContainer.html('');
                            container.removeClass('hidden');
                        });
                        initializeSelectizeProjectsAndClients();
                    },
                    onSave: function(defaultHandler, callbacks) {
                        var apiResult = defaultHandler;
                        if(apiResult.success == true) {
                            callbacks.onResponderCreate(apiResult);
                            newContainer.html('');
                            container.removeClass('hidden');
                        }
                    },
                    confirmSubmit: true,
                    confirmSubmitMessage: 'Czy na pewno chcesz wyslac wiadomosc do osob kontaktowych?',
                    onResponderCreate: function(apiResult) {}
                });
            });
        },
        addProjectFileCreateHandler: function(container, newContainer) {
          $('.project-file-create').on('click tap', function(ev) {
              ev.preventDefault();
              initializeForm(newContainer, $(this).data('url'), {
                  onLoad: function () {
                      container.addClass('hidden');
                      newContainer.removeClass('hidden');
                      newContainer.find('.buttons').append('<button class="back-button btn btn-sm btn-warning">Wróć</button>');
                      newContainer.find('.back-button').on('click tap', function (ev) {
                          ev.preventDefault();
                          newContainer.html('');
                          container.removeClass('hidden');
                      });
                  },
              });
          });
        },
        addTimelogCreateHandler: function(container, newContainer) {
            $('.timelog-create').on('click tap', function(ev) {
                ev.preventDefault();
                var timelogHoursSummary = $('.timelogs-summary-amount');
                initializeForm(newContainer, $(this).data('url'), {
                    onLoad: function() {
                        container.addClass('hidden');
                        newContainer.removeClass('hidden');
                        newContainer.find('.submit-form').before('<button class="back-button btn btn-sm btn-warning">Wróć</button>');
                        newContainer.find('.back-button').on('click tap', function(ev) {
                            ev.preventDefault();
                            newContainer.html('');
                            container.removeClass('hidden');
                        });
                        var $loggedTime = $('#logged_time');
                        $loggedTime.focus();
                        $loggedTime.keypress(function (e) {
                            if (e.which == 13) {
                                newContainer.find('.submit-form').click();
                                return false;
                            }
                        });
                        initializeSelectizeProjectsAndClients();
                    },
                    onSave: function(defaultHandler, callbacks) {
                        var apiResult = defaultHandler;
                        if(apiResult.success == true) {
                            callbacks.onTimelogsCreate(apiResult);
                            newContainer.html('');
                            container.removeClass('hidden');
                        }
                    },
                    onTimelogsCreate: function(apiResult) {
                        container.find('.timelogs').append('<li data-timelog-id="'+apiResult.timelogId+'">'+apiResult.date + ' | <a href="#/edit/timelog" class="timelog-edit" data-type="GET" data-url="'+apiResult.editUrl+'"><span data-timelog-logged-time="' + apiResult.loggedTime + '">' + apiResult.loggedTime + 'h</span></a> | ' + apiResult.userName + ' | ' + apiResult.studioNotes + '</li>');
                        timelogHoursSummary.html(parseFloat(timelogHoursSummary.html())+parseFloat(apiResult.loggedTime));
                        form.options.addTimelogEditHandler($('#eventFormContainer'), $('#eventSubFormContainer'));
                    }
                });
            });
        },
        addTimelogEditHandler: function(container, newContainer) {
            $('.timelog-edit').on('click tap', function(ev) {
                ev.preventDefault();
                var timelogHoursSummary = $('.timelogs-summary-amount');
                initializeForm(newContainer, $(this).data('url'), {
                    onLoad: function() {
                        container.addClass('hidden');
                        newContainer.removeClass('hidden');
                        newContainer.find('.submit-form').before('<button class="back-button btn btn-sm btn-warning">Wróć</button>');
                        newContainer.find('.back-button').on('click tap', function(ev) {
                            ev.preventDefault();
                            newContainer.html('');
                            container.removeClass('hidden');
                        });
                        var $loggedTime = $('#logged_time');
                        $loggedTime.focus();
                        $loggedTime.keypress(function (e) {
                            if (e.which == 13) {
                                newContainer.find('.submit-form').click();
                                return false;
                            }
                        });
                        initializeSelectizeProjectsAndClients();
                    },
                    onSave: function(defaultHandler, callbacks) {
                        var apiResult = defaultHandler;
                        if(apiResult.success == true) {
                            callbacks.onTimelogsUpdate(apiResult);
                            newContainer.html('');
                            container.removeClass('hidden');
                        }
                    },
                    afterDestroyCallback: function(apiResult) {
                        var oldTime = container.find('.timelogs li[data-timelog-id="'+apiResult.timelogId+'"]');
                        // Recalculate time.
                        var timeToSubstract = oldTime.find('span[data-timelog-logged-time]').data('timelog-logged-time');
                        timelogHoursSummary.html(parseFloat(timelogHoursSummary.html())-parseFloat(timeToSubstract));
                        // Remove oldTime DOM and return previous window.
                        oldTime.remove();
                        newContainer.html('');
                        container.removeClass('hidden');
                    },
                    onTimelogsUpdate: function(apiResult) {
                        var oldTime = container.find('.timelogs li[data-timelog-id="'+apiResult.timelogId+'"]').find('span[data-timelog-logged-time]').data('timelog-logged-time');
                        container.find('.timelogs li[data-timelog-id="'+apiResult.timelogId+'"]').html(apiResult.date + ' | <a href="#/edit/timelog" class="timelog-edit" data-type="GET" data-url="'+apiResult.editUrl+'"><span data-timelog-logged-time="' + apiResult.loggedTime + '">' + apiResult.loggedTime + 'h</span></a> | ' + apiResult.userName + ' | ' + apiResult.studioNotes + '</li>');
                        timelogHoursSummary.html(parseFloat(timelogHoursSummary.html())-parseFloat(oldTime)+parseFloat(apiResult.loggedTime));
                        form.options.addTimelogEditHandler($('#eventFormContainer'), $('#eventSubFormContainer'));
                    }
                });
            });
        },
        addLectorEditHandler: function(container, newContainer) {
            $('.lector-edit').on('click tap', function(ev) {
                var $clickedLector = $(this);
                ev.preventDefault();
                initializeForm(newContainer, $(this).data('url'), {
                    onLoad: function() {
                        container.addClass('hidden');
                        newContainer.removeClass('hidden');
                        newContainer.find('.submit-form').before('<button class="back-button btn btn-sm btn-warning">Wróć</button>');
                        newContainer.find('.back-button').on('click tap', function(ev) {
                            ev.preventDefault();
                            newContainer.html('');
                            container.removeClass('hidden');
                        });
                        initializeSelectizeProjectsAndClients();
                    },
                    onSave: function(defaultHandler, callbacks) {
                        var apiResult = defaultHandler;
                        if(apiResult.success == true) {
                            callbacks.onLectorsUpdate(apiResult);
                            newContainer.html('');
                            container.removeClass('hidden');
                        }
                    },
                    onLectorsUpdate: function(apiResult) {
                        $clickedLector.html(apiResult.renderedName);
                    }
                });
            });
        },
        addEventEditHandler: function(container, newContainer, callbacks) {
            $('.event-edit').on('click tap', function (ev) {
                ev.preventDefault();
                initializeForm(newContainer, $(this).data('url'), {
                    onLoad: function () {
                        container.addClass('hidden');
                        newContainer.removeClass('hidden');

                        // Remove clients and projects button to prevent another modal within existing modal.
                        newContainer.find('.unassigned-clients').remove();
                        newContainer.find('.unassigned-projects').remove();

                        newContainer.find('.submit-form').before('<button class="back-button btn btn-sm btn-warning">Wróć</button>');
                        newContainer.find('.back-button').on('click tap', function (ev) {
                            ev.preventDefault();
                            newContainer.html('');
                            container.removeClass('hidden');
                        });
                        newContainer.find('.submit-form').after('<button class="delete-button btn btn-sm btn-danger">Usuń</button>');
                        newContainer.find('.delete-button').on('click tap', function (ev) {
                            ev.preventDefault();
                            var removeEvent = function() {
                                $.ajax({
                                    url: newContainer.find('form').attr('action'),
                                    type: 'DELETE',
                                    dataType: 'json',
                                    success: function (res) {
                                        ev.preventDefault();
                                        newContainer.html('');
                                        container.removeClass('hidden');
                                        $('#backendModal').modal('hide');
                                    }
                                });
                            }
                            swal({
                                title: "Uwaga",
                                text: "Czy na pewno chcesz usunąć ten Event?",
                                type: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#DD6B55",
                                confirmButtonText: "Tak",
                                cancelButtonText: "Nie",
                                closeOnConfirm: true
                            }, function(confirmed) {
                                confirmed === true ? removeEvent() : false;
                            });
                        });
                        initializeSelectizeProjectsAndClients(false, true);
                        callbacks.onLoad();
                    },
                    onSave: function (defaultHandler, callbacks) {
                        var apiResult = defaultHandler;
                        if (apiResult.success == true) {
                            callbacks.onEventUpdate(apiResult);
                            newContainer.html('');
                            container.removeClass('hidden');
                        }
                    },
                    onEventUpdate: function (apiResult) {

                        var $clientEdit = $('.client-edit');
                        var $projectEdit = $('.project-edit');
                        var clientUrl = $clientEdit.data('url')
                        var projectUrl = $projectEdit.data('url')

                        $clientEdit.data('url', clientUrl.replace(/id=[0-9]+/gi,'id=' + apiResult.clientId));
                        $clientEdit.html(apiResult.clientName);
                        $('.client-public-notes').html(apiResult.clientPublicNotes);
                        $('.client-studio-notes').html(apiResult.clientStudioNotes);

                        $projectEdit.html(apiResult.projectName);
                        $projectEdit.data('url', projectUrl.replace(/id=[0-9]+/gi,'id=' + apiResult.projectId));
                        $('.project-public-notes').html(apiResult.clientPublicNotes);
                        $('.project-studio-notes').html(apiResult.clientStudioNotes);

                        $('.event-public-notes').html(apiResult.publicNotes);
                        $('.event-studio-notes').html(apiResult.studioNotes);

                        var $studios = $('.studios');
                        $studios.html('');
                        $.each(apiResult.studios, function(index, el) {
                            $studios.append('<li>'+el.name+'</li>');
                        });

                        var $lectors = $('.lectors');
                        $lectors.html('');
                        $.each(apiResult.lectors, function(index, el) {
                            $lectors.append('<li>'+el.name+'</li>');
                        });

                        var $producents = $('.producents');
                        $producents.html('');
                        $.each(apiResult.producents, function(index, el) {
                            $producents.append('<li>'+el.name+'</li>');
                        });

                        form.options.addClientEditHandler(container, newContainer);
                        form.options.addProjectEditHandler(container, newContainer);


                    }
                });
            });
        }
    };

    function initializeSelectizeProjectsAndClients(loadLastClient, blockLoadLastProject, forceLoadLastProject, forceLoadGivenClient)
    {
        var $clientsSelect = $('#clientsSelect, #clientsSelect-2');
        var $projectsSelect = $('#projectsSelect, #projectsSelect-2');

        if(loadLastClient === true) {
            loadSelectizeLastClient($clientsSelect)
        }

        $clientsSelect.selectize(selectizeSearch("clients"));
        $('#targetClientsSelect').selectize(selectizeSearch("clients", undefined, true));
        $projectsSelect.selectize(selectizeSearch("projects"));

        if($clientsSelect.length > 0) {
            var clientId = $clientsSelect[0].selectize.getValue();

            // If we force to load given client - select it right away.
            if (typeof forceLoadGivenClient !== "undefined") {
                selectClientWithId(forceLoadGivenClient, $clientsSelect)
            }

            if((clientId != 1 || forceLoadLastProject == true) && $projectsSelect.length > 0) {
                if(blockLoadLastProject === true) {
                    var projectId = $projectsSelect[0].selectize.getValue();
                    loadSelectizeProjectsForClient(clientId, $projectsSelect, projectId);
                } else {
                    loadSelectizeProjectsForClient(clientId, $projectsSelect);
                }
            }
            toggleUnassignedButtonClass("clients", clientId);
        }
        if($projectsSelect.length > 0) {
            var projectId = $projectsSelect[0].selectize.getValue();
            toggleUnassignedButtonClass("projects", projectId);
        }
    }

    function selectClientWithId(clientId, clientsSelectize)
    {
        clientsSelectize[0].selectize.setValue(clientId);
        toggleUnassignedButtonClass("clients", clientId);
    }

    function loadSelectizeLastClient(clientsSelectize)
    {
        $.ajax({
            url: '/api/search',
            type: 'GET',
            dataType: 'json',
            data: {type: "clients"},
            success: function (res) {
                clientsSelectize[0].selectize.addOption({id: res.data[0].id, name: res.data[0].name});
                clientsSelectize[0].selectize.addItem(res.data[0].id);
            }
        });
    }

    function loadSelectizeProjectsForClient(value, projectsSelectize, projectId)
    {
        // Cleanup old projects.
        projectsSelectize[0].selectize.destroy();
        projectsSelectize.find('option').remove();

        // Bind new search mechanics based on filtering by clientId
        projectsSelectize.selectize(selectizeSearch("projects", {"filter" : {"clientId" : value}}));

        // Manually query the .ajax and fill.
        $.ajax({
            url: '/api/search',
            type: 'GET',
            dataType: 'json',
            data: {type: "projects", "filter" : value},
            success: function (res) {
                $.each(res.data, function(index, el) {
                    projectsSelectize[0].selectize.addOption({id: el.id, name: el.name});
                    projectsSelectize[0].selectize.addItem(el.id, true);
                });
                if(typeof projectId !== "undefined") {
                    projectsSelectize[0].selectize.setValue(projectId);
                } else {
                    // Load last option.
                    var options = Object.keys(projectsSelectize[0].selectize.options);
                    projectsSelectize[0].selectize.setValue(options.pop());
                }
            }
        });
    }

    function unInitializeOnSelect($clientsProjectSelectObject)
    {
        $clientsProjectSelectObject.unbind("change");
        $('.clear-filtering').unbind("click tap").hide();
    }

    function filterCalendar(searchType, id)
    {
        if(typeof id !== "undefined" && typeof searchType !== "undefined") {
            $('.calendar-item[data-' + searchType + '-id!=' + id + ']').hide();
            $('.calendar-item[data-' + searchType + '-id*=' + id + ']').show();
            $('.clear-filtering').show();
        }
    }

    function initializeOnSelect($clientsProjectSelectObject, searchType, reinitialize)
    {
        if(reinitialize === true) {
            $clientsProjectSelectObject[0].selectize.setValue('');
            $('.calendar-item').show();
        }

        $clientsProjectSelectObject.on("change", function(obj) {
            var id = obj.currentTarget.value;
            globalSearchType = searchType;
            globalSearchTypeId = id;
            filterCalendar(globalSearchType, globalSearchTypeId);
        });

        $('.clear-filtering').on("click tap", function() {
            unInitializeOnSelect($clientsProjectSelectObject);
            initializeOnSelect($clientsProjectSelectObject, searchType, true);
        });

    }

    function selectizeSearch(searchType, el, silent) {
        if (silent === undefined) {
            silent = false;
        }

        var data = {
            type: searchType
        };
        if(el !== undefined) {
            el.filter !== undefined ? data["filter"] = el.filter.clientId : undefined;
        }

        return {
            valueField: 'id',
            labelField: 'name',
            searchField: ['name'],
            maxOptions: 300,
            loadThrottle: 500,
            create: false,
            render: {
                option: function (item, escape) {
                    return '<div>' + escape(item.name) + '</div>';
                }
            },
            load: function (query, callback) {
                if (!query.length) return callback();
                data["q"] = query;
                $.ajax({
                    url: '/api/search',
                    type: 'GET',
                    dataType: 'json',
                    data: data,
                    error: function () {
                        callback();
                    },
                    success: function (res) {
                        callback(res.data);
                    }
                });
            },
            onChange: function(value) {
                if (!silent) {
                    selectizeChange(searchType, value)
                }
            },
        };
    }

    function selectizeChange(searchType, value)
    {
        // If its unassigned then we show up the button.
        toggleUnassignedButtonClass(searchType, value);

        // If clients are selected and projectsSelect is present.
        var $el = $('#projectsSelect, #projectsSelect-2');
        if(searchType == "clients" && $el.length > 0) {
            loadSelectizeProjectsForClient(value, $el);
        }
    }

    function toggleUnassignedButtonClass(type, value)
    {
        if(value == 1) {
            $('.unassigned-' + type).removeClass('hidden');
        } else {
            $('.unassigned-' + type).addClass('hidden');
        }
    }

    // If theres clientsProjectSelect and clientsProjectSwitch present - it means we need to load an default projects.
    if($clientsProjectSelectObject.length > 0 && $clientsProjectSwitchObject.length > 0) {
        $.each($clientsProjectSelectObject.data('projects'), function(index, el) {
            $filteringSelect[0].selectize.addOption({id: index, name: el});
            $filteringSelect[0].selectize.addItem(index);
        });
        $clientsProjectSwitchObject.change(function() {
            unInitializeOnSelect($filteringSelect);
            $filteringSelect[0].selectize.clearOptions();
            switch($(this).val()) {
                case "projects":
                    searchType = "projects";
                    $.each($clientsProjectSelectObject.data('projects'), function(index, el) {
                        $filteringSelect[0].selectize.addOption({id: index, name: el});
                        $filteringSelect[0].selectize.addItem(index);
                    });
                break;
                case "clients":
                    searchType = "clients";
                    $.each($clientsProjectSelectObject.data('clients'), function(index, el) {
                        $filteringSelect[0].selectize.addOption({id: index, name: el});
                        $filteringSelect[0].selectize.addItem(index);
                    });
                break;
                case "sound-engineers":
                    searchType = "sound-engineers";
                    $.each($clientsProjectSelectObject.data('sound-engineers'), function(index, el) {
                        $filteringSelect[0].selectize.addOption({id: index, name: el});
                        $filteringSelect[0].selectize.addItem(index);
                    });
                break;
                case "studios":
                    searchType = "studios";
                    $.each($clientsProjectSelectObject.data('studios'), function(index, el) {
                        $filteringSelect[0].selectize.addOption({id: index, name: el});
                        $filteringSelect[0].selectize.addItem(index);
                    });
                    break;
            }
            initializeOnSelect($filteringSelect, searchType, true);
        });
        // Make sure select is empty on load.
        $clientsProjectSelectObject[0].selectize.setValue('');
    }
    initializeOnSelect($filteringSelect, "projects");

    if($calendar.length > 0) {
        var soundEngineersIterator = 1;
        var soundEngineersArray = [];
        var opts = {};

        if($(window).width() < 768) {
            opts = {
                header: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'listDay,listWeek'
                },
                defaultView: 'listWeek',
            }
        } else {
            opts = {
                header: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'month,agendaWeek,agendaDay'
                }
            }
        }

        var fullCalendarOpts = {
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,agendaWeek,agendaDay'
            },
            lang: 'pl',
            firstDay: 1,
            timeFormat: 'H(:mm)',
            axisFormat: 'H(:mm)',
            allDayText: 'Cały dzień',
            monthNames : [
                'Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj',
                'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień',
                'Październik', 'Listopad', 'Grudzień'
            ],
            monthNamesShort : ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
            dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
            dayNamesShort: ['Niedz', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob'],
            buttonText: {
                today: 'Dzisiaj',
                month: 'Miesiąc',
                week: 'Tydzień',
                day: 'Dzień'
            },
            eventAfterAllRender: function(view, element) {
                filterCalendar(globalSearchType, globalSearchTypeId);
            },
            dayClick: function(date, allDay) {

                if(isRealizator === true || isAdmin === true || isProducent === true) {

                    // Clicked on the day number
                    if ($(allDay.target).is('td.fc-day-number')) {
                        $calendar.fullCalendar('changeView', 'agendaDay');
                        $calendar.fullCalendar('gotoDate', date.format('YYYY-MM-DD'));
                    } else {
                        var allEvents = $('#calendar').fullCalendar('clientEvents');

                        // Get latest event if it exists within given day.
                        var latestEvent = undefined;
                        for (var i = 0; i < allEvents.length; i++) {
                            if (date.format('YYYY-MM-DD') === allEvents[i].start.format('YYYY-MM-DD')) {
                                // If there are no latest event - create one.
                                if (typeof latestEvent === "undefined") {
                                    latestEvent = allEvents[i];
                                } else {
                                    if (allEvents[i].end) {
                                        // Otherwise, use latest event only if its after the previous one.
                                        if (allEvents[i].end.isAfter(latestEvent.end)) {
                                            latestEvent = allEvents[i];
                                        }
                                    }
                                }
                            }
                        }

                        var today = new Date();
                        var yesterday = today.setDate(today.getDate() - 1);
                        var initializeCreateModal = function () {

                            initializeForm(
                                $('#eventFormContainer'),
                                '/api/calendar/events/form?date=' + date.format('YYYY-MM-DD'), {
                                    onSave: function (res) {

                                        var closeModalAndRefetchEvents = function() {
                                            // Hide modal
                                            $('#backendModal').modal('hide');
                                            // Fetch calendar
                                            $calendar.fullCalendar('refetchEvents')
                                        };

                                        if(res.success === true && typeof res.notification !== "undefined") {
                                            swal({
                                                title: "Uwaga",
                                                text: res.notification,
                                                type: "warning",
                                                showCancelButton: false,
                                                confirmButtonColor: "#DD6B55",
                                                confirmButtonText: "Potwierdź",
                                                closeOnConfirm: true
                                            }, function(confirmed) {
                                                closeModalAndRefetchEvents();
                                            });
                                        } else {
                                            closeModalAndRefetchEvents();
                                        }
                                    },
                                    onLoaded: function () {
                                        form.options.addClientCreateHandler($('#eventFormContainer'), $('#eventSubFormContainer'));
                                        form.options.addProjectCreateHandler($('#eventFormContainer'), $('#eventSubFormContainer'));
                                        form.options.addDatesHandler();
                                        form.options.addAttachmentsHandler();
                                        initializeSelectizeProjectsAndClients();
                                        $('#eventFormContainer').find('.submit-form').before('<button class="btn btn-sm btn-warning" data-dismiss="modal">Zamknij</button>');

                                        if (typeof latestEvent !== "undefined") {
                                            var endHour = latestEvent.end;
                                            // It works only after 19, because max to time is 21.
                                            if(parseInt(endHour.utc().format('H')) <= 19) {
                                                var from = moment(endHour.utc().add(30, 'minutes').format()).utc().format('H:mm');
                                                var to = moment(endHour.utc().add(90, 'minutes').format()).utc().format('H:mm');
                                                $('select[name="from[]"]').val(from);
                                                $('select[name="to[]"]').val(to);
                                            }

                                        }

                                    }
                                }
                            );
                            $('#backendModal').modal('show');
                        }

                        if (yesterday > date) {
                            swal({
                                title: "Uwaga",
                                text: "Zamierzasz dodać Event do dnia który już nastąpił, czy na pewno chcesz to zrobić?",
                                type: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#DD6B55",
                                confirmButtonText: "Tak",
                                cancelButtonText: "Nie",
                                closeOnConfirm: true
                            }, function (confirmed) {
                                confirmed === true ? initializeCreateModal() : false;
                            });
                        } else {
                            initializeCreateModal();
                        }
                    }

                }
            },
            eventRender: function(event, element) {
                events[event.id] = event;

                element.addClass('calendar-item');
                if(event.pulsingStatus === true && event.unreportedPulsingStatus === false) {
                    element.addClass('pulsing');
                }
                if(event.unreportedPulsingStatus === true) {
                    element.addClass('pulsing-faster');
                }
                if(event.hasTimelog === false) {
                    element.addClass('missing-timelog');
                    element.append('<i class="fa fa-clock-o" aria-hidden="true"></i>');
                }
                if (event.session_with_client) {
                    element.append('<i class="fa fa-exclamation-circle" style="margin-left: 5px;"></i>');
                }
                if(event.grayscaleStatus === true || (isRealizator === true && event.eventIsAttachedToCurrentlyLoggedInRealizator === false)) {
                    element.addClass('grayscale');
                }
                element.attr('data-projects-id', event.projectId);
                element.attr('data-clients-id', event.clientId);
                element.attr('data-event-id', event.id);
                element.attr('data-sound-engineers-id', event.soundEngineers);
                element.attr('data-studios-id', event.studios);

                // Create popup for given element.
                var studioNamesStr = '';
                event.studiosObj.map(function(studio) {
                    studioNamesStr += studio.name + '<br>';
                    element.addClass('studio');
                    element.addClass('studio-' + studio.id);
                });
                var soundEngineersNamesStr = '';
                event.soundEngineersObj.map(function(engineer) {
                    soundEngineersNamesStr += engineer.name + '<br>';
                    element.addClass('sound-engineer');

                    // Make sure we attach only 1,2,3,4,5 values instead of using actual IDs.
                    if(typeof soundEngineersArray[engineer.id] === "undefined") {
                        soundEngineersArray[engineer.id] = soundEngineersIterator;
                        soundEngineersIterator++;
                    }
                    element.addClass('sound-engineer-' + soundEngineersArray[engineer.id]);
                    // Attach soundEngineers
                    element.find('.fc-content').append('<span class="fc-sound-engineers" style="color: ' + engineer.color + '">' + engineer.name + '</span>');
                });
                var lectorNamesStr = '';
                event.lectors.map(function(lector) {
                    lectorNamesStr += lector.name + '<br>';
                });

                // Add time to the fc-time
                element.find('span.fc-time').html(event.from + ' - ' + event.to);

                element.hover(function(e) {
                    $elementPopup.find('.client-popup').html(event.clientName);
                    $elementPopup.find('.project-popup').html(event.projectName);
                    $elementPopup.find('.start-popup').html(event.from);
                    $elementPopup.find('.end-popup').html(event.to);
                    $elementPopup.find('.studios-popup').html(studioNamesStr);
                    $elementPopup.find('.lectors-popup').html(lectorNamesStr);
                    $elementPopup.find('.sound-engineers-popup').html(soundEngineersNamesStr);
                    var offset = $('#calendar').offset();
                    $elementPopup.removeClass("hidden");
                }, function() {
                    $elementPopup.addClass("hidden");
                })

                var initializeEditModal = function() {
                    initializeForm($('#eventFormContainer'), '/api/calendar/events/info?id=' + event.id + '&date=' + event.date, {
                        onLoaded: function () {
                            var $editForm = $('#eventFormContainer');
                            var $editSubForm = $('#eventSubFormContainer');
                            form.options.addClientEditHandler($editForm, $editSubForm);
                            form.options.addProjectEditHandler($editForm, $editSubForm);
                            form.options.addResponderHandler($editForm, $editSubForm);
                            form.options.addProjectFileCreateHandler($editForm, $editSubForm);
                            form.options.addTimelogCreateHandler($editForm, $editSubForm);
                            form.options.addLectorEditHandler($editForm, $editSubForm);
                            form.options.addTimelogEditHandler($editForm, $editSubForm);
                            form.options.addEventEditHandler($editForm, $editSubForm, {
                                onLoad: function() {
                                    form.options.addDatesHandler();
                                }
                            });
                        }
                    });
                };

                element.on("click tap", function() {
                    initializeEditModal();
                    $('#backendModal').modal('show');
                });
            },
            eventDrop: function (event, delta, revertFunc, jsEvent, ui, view) {
                updateEvent(event, revertFunc);
            },
            eventResize: function (event, delta, revertFunc) {
                updateEvent(event, revertFunc);
            },
            events: "/api/calendar/events",
            editable: true,
            droppable: false
        };
        $calendar.fullCalendar($.extend(fullCalendarOpts, opts));
    }

    updateEvent = function (event, revertFunc) {
        bootbox.confirm(
            'Czy na pewno chcesz zmienić dane dla eventu: "'
            + event.title + '" na od: '
            + event.start.format('DD.MM.YYYY HH:mm') + ' do: '
            + event.end.format('DD.MM.YYYY HH:mm')  + '?',
            function (result) {
                if (result) {
                    $.LoadingOverlay('show');

                    api.post(
                        '/api/calendar/events/' + event.id + '/update',
                        {
                            from: event.start.format('YYYY-MM-DD HH:mm:ss'),
                            to: event.end.format('YYYY-MM-DD HH:mm:ss'),
                        },
                        function (response) {
                            $.LoadingOverlay('hide');
                        }
                    );
                } else {
                    revertFunc();
                }
            }
        );
    };

    $('.prevent-default').on("click tap", function(ev) {
        ev.preventDefault();
    });

    var refetchEventsOnModalClose = function() {
        $('#backendModal').on("hidden.bs.modal", function () {
            $calendar.fullCalendar('refetchEvents');
            $('#eventFormContainer').removeClass('hidden');
            $('#eventSubFormContainer').addClass('hidden');
        });
    };
    refetchEventsOnModalClose();
    var unbindModalClose = function() {
        $('#backendModal').unbind("hidden.bs.modal");
    };


    $createForm = $('.create-form');
    if($createForm.length > 0) {
        initializeForm($createForm, $createForm.data('url'), {
            onLoaded: function() {
                form.options.addAttachmentsHandler();
            },
            onSave: function() {
                if($createForm.find('form').data('redirect')) {
                    location.href = $createForm.find('form').data('redirect');
                }
            }
        });
    }
    $editForm = $('.edit-form');
    if($editForm.length > 0) {
        initializeForm($editForm, $editForm.data('url'), {
            onLoaded: function() {
                form.options.addAttachmentsHandler();
            },
            onSave: function() {
                if($editForm.find('form').data('redirect')) {
                    location.href = $editForm.find('form').data('redirect');
                }
            }
        });
    }
    $('.selectize').selectize();
    $('.table').DataTable(getDataTableDefaultOptions());
    var hideTrTablesOnMobile = function() {
        var $elementsToHide = $('.table .desktop');
        if($(window).width() < 768) {
            $elementsToHide.hide();
        } else {
            $elementsToHide.show();
        }
    }
    hideTrTablesOnMobile();
    $(window).on('resize', function() {
        hideTrTablesOnMobile();
    });

    $(document).on('click', '.generate-pdf', function (e) {
        e.preventDefault();

        var html = $('#calendar').parent().clone();

        html.find('.fc-left').remove();
        html.find('.fc-right').remove();

        $.LoadingOverlay('show');

        api.post(
            '/api/calendar/generate-pdf',
            {
                html: html.html()
            },
            function (response) {
                location.href = response.success;
            }
        );
    });

    $(document).on('change', '#eventFormContainer select[name="project_id"]', function () {
        api.get('/api/projects/' + $(this).val(), {}, function (response) {
            var clientId = response.success.client_id;

            if (clientId != 1) {
                $.LoadingOverlay('show');

                $('select[name="client_id"]')[0].selectize.setValue(clientId, true);

                toggleUnassignedButtonClass('clients', clientId);

                $.LoadingOverlay('hide');
            }
        });
    });
});
